export default {
    state: {
        data: {
            token: {USDC: 0, USDT: 0},
            price: {},
            balance: 0,
        },
    },
    mutations: {
        UPDATE_WALLET(state, payload) {
            Object.keys(payload).forEach((key) => {
                state.data[key] = payload[key];
            });
            state.data.balance = calculateBalance(state.data);
        },
    },
    actions: {
        async walletData({commit}, newData) {
            commit("UPDATE_WALLET", newData);
        },
    },
    getters: {
        wallet(state) {
            return state.data;
        },
    },
};

// Функция для вычисления баланса
function calculateBalance(data) {
    let balance = 0;
    for (const token in data.token) {
        if (data.token.hasOwnProperty(token)) {
            balance += data.token[token] * data.price[token];
        }
    }
    return balance;
}
