export default {
    state: {
        user: {},
        auth: false,
        loaded: false,
        games: [
            {img: "1.webp", url: "https://t.me/MirrionBeyondbot?start"},
            {img: "2.webp", url: ""},
            {img: "3.webp", url: ""},
        ],
        time: 0,
    },
    mutations: {
        SET_RUN(state, newData) {
            if (newData.user) {
                state.auth = true;
            }
            state.loaded = true;
        },
        UPDATE_DATA(state, payload) {
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key];
            });
        },
        UPDATE_USER(state, payload) {
            if (!state.auth) state.auth = true;
            Object.keys(payload).forEach((key) => {
                state.user[key] = payload[key];
            });
        },
        OUT_USER(state) {
            state.auth = false;
            state.user = {};
        },
        updateTime(state) {
            const now = new Date();
            const Offset = now.getTimezoneOffset() / 60 + 1;
            state.time = Math.floor(now.getTime() / 1000) - Offset * 3600;
        },
    },
    actions: {
        async run({ commit }, newData) {
            commit("updateTime");
            commit("SET_RUN", newData);
            commit("UPDATE_DATA", newData);
        },
        async updateUser({commit}, newData) {
            commit("UPDATE_USER", newData);
        },
        async update({commit}, newData) {
            commit("UPDATE_DATA", newData);
        },
        async userOut({commit}) {
            commit("OUT_USER");
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        app(state) {
            const auth = state.auth;
            const loaded = state.loaded;
            const news = state.news;
            const slider = state.slider;
            const topRef = state.topRef;
            const drop = state.drop;
            const quests = state.quests;
            const games = state.games;
            const time = state.time;

            return {loaded, auth, news, slider, topRef, quests, drop, games, time};
        },
    },
};
