<template>
    <div class="nk-footer">
        <div>
            <div class="nk-footer-wrap">
                <div class="nk-footer-copyright d-none d-md-block">© <router-link to="/">Mirrion Beyond</router-link></div>
                <div class="nk-footer-links">
                    <ul class="nav-sm">
                        <li class="nav-item">
                            <div class="d-flex flex-wrap justify-content-center mt5">
                                <router-link to="/privacy" class="footer-link px-2"> Privacy </router-link>
                                <a href="https://mirrions-organization.gitbook.io/mirrion" target="_blank" class="footer-link px-2"> About</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="nk-footer-links">
                    <ul class="nav-sm">
                        <li class="nav-item">
                            <div class="d-flex flex-wrap justify-content-center mt5">
                                <a class="footer-link" href="https://x.com/TheMirrion" target="_blank">
                                    <i class="bi bi-twitter"></i>
                                </a>
                                <a class="footer-link" href="https://t.me/TheMirrion" target="_blank">
                                    <i class="bi bi-telegram"></i>
                                </a>
                                <a class="footer-link" href="https://discord.com/invite/cQVNmk5X9B" target="_blank">
                                    <i class="bi bi-discord"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.navbar-nav > li {
    font-size: 20px;
}
</style>
