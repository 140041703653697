<template>
    <transition name="modal-slide-up">
        <div class="modal fade" @click="this.$modal(id)" :id="id">
            <div class="modal-dialog" :class="size" role="document" @click.stop>
                <div class="modal-content">
                    <a @click="this.$modal(id)" class="close" data-bs-dismiss="modal"><em class="bi bi-x"></em></a>
                    <div class="modal-body modal-body-md" :class="{'p-0': padding==0}">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: "",
        },
        size: {
            type: String,
            default: "sm",
        },
        padding: {
            type: String,
            default: "1",
        },
    },
};
</script>

<style scoped>
.modal {
    visibility: hidden;
    background: #0000008f;
    transition: opacity 0.2s ease, visibility 0s 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 999999;
    padding: 20px; /* Внутренний отступ для удобства */
    box-sizing: border-box; /* Учитываем padding в общей ширине и высоте */
}

.modal.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.modal-dialog {
    transition: transform 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-dialog.sm {
    max-width: 500px;
}
.modal-dialog.md {
    max-width: 800px;
}


.modal-content {
    position: relative;
    width: 100%;
    margin: auto; /* Отступы для центрирования */
    overflow: hidden; /* Отключаем прокрутку внутри модального окна */
    box-sizing: border-box; /* Учитываем padding и border в общей ширине и высоте */
}

.modal-body {
    background: #0d0c26;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    border-radius: 20px;
}

.close {
    position: absolute;
    right: 0px;
    padding: 8px;
    font-size: 25px;
}
</style>
