<template>
    <div class="nk-header nk-header-fixed">
        <div>
            <div class="nk-header-wrap" style="height: 50px">
                <div class="nk-header-app-name">
                    <div class="">
                        <router-link to="/">
                            <img class="logo-img" src="../../assets/icons/mirrion.webp" alt="logo" />
                        </router-link>
                    </div>
                    <div :class="{'nk-sidebar-overlay': mobMenu}" @click="mobMenu = !mobMenu">
                        <div class="nk-header-menu" :class="{'nk-sidebar-active': mobMenu}" @click.stop>
                            <div class="nk-header-mobile">
                                <div class="nk-header-brand">
                                    <router-link to="/" class="logo-link">
                                        <img class="logo-img" src="../../assets/icons/mirrion.webp" alt="logo" />
                                    </router-link>
                                </div>
                            </div>
                            <ul class="nk-menu nk-menu-main ui-s2">
                                <li class="nk-menu-item">
                                    <router-link to="/" @click="this.$scrollTo('games')" class="nk-menu-link">{{ $t("Games") }}</router-link>
                                </li>
                                <li class="nk-menu-item">
                                    <router-link to="" class="nk-menu-link text-light">{{ $t("Marketplace") }}</router-link>
                                </li>
                                <li class="nk-menu-item">
                                    <router-link to="/quests" class="nk-menu-link">{{ $t("Daily Quest") }}</router-link>
                                </li>
                                <li class="nk-menu-item">
                                    <router-link to="/news" class="nk-menu-link">{{ $t("News") }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="nk-header-tools p-0">
                    <ul class="nk-quick-nav">
                        <li class="nk-menu-item" style="max-width: 120px">
                            <router-link to="/drop" class="nk-menu-link p-0"><img src="@/assets/images/drop.png" /></router-link>
                        </li>
                        <li class="nk-menu-item d-none d-sm-block" style="max-width: 120px">
                            <a href="https://t.me/MirrionBeyondbot?start" target="_blank" class="nk-menu-link p-0"><img src="@/assets/images/launch.png" /></a>
                        </li>
                        <li v-if="user && user.prava >= 5">
                            <router-link to="/panel" class="nk-quick-nav-icon d-none d-lg-block"><i class="icon ni bi-gear"></i></router-link>
                            <a @click="panelMenu = !panelMenu" class="nk-quick-nav-icon d-lg-none"><i class="icon ni bi-gear"></i></a>
                        </li>
                        <li class="dropdown user-dropdown">
                            <div class="d-flex align-items-center" style="padding: 2px; background: #101c29">
                                <div class="d-flex" style="padding: 2px"><em class="icon ni ni-user-alt-fill text-light fs-20px"></em></div>
                                <div class="ps-0" style="padding: 2px 0; background: linear-gradient(to right, #0b1c29 20%, #6c15df)">
                                    <div class="text-center fs-16px" style="background: #040718; min-width: 60px; padding: 0 2px">
                                        <Dropdown v-if="app.auth">
                                            <a class="dropdown-toggle text-white p-1">
                                                {{ user.login }}
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-md dropdown-menu-end is-light">
                                                <div class="dropdown-inner user-card-wrap bg-lighter">
                                                    <div class="user-card">
                                                        <div class="user-info">
                                                            <span class="lead-text">{{ user.mail }}</span>
                                                        </div>
                                                        <div class="user-action">
                                                            <router-link to="/profile/t_setting" class="btn btn-icon me-n2"><em class="icon ni ni-setting"></em></router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dropdown-inner">
                                                    <ul class="link-list">
                                                        <li>
                                                            <router-link to="/wallet"
                                                                ><em class="icon ni ni-wallet-alt"></em><span>{{ $t("Wallet") }} </span></router-link
                                                            >
                                                        </li>
                                                        <li>
                                                            <router-link to="/affiliate"
                                                                ><em class="icon ni ni-users-fill"></em><span>{{ $t("Affiliate program") }}</span>
                                                            </router-link>
                                                        </li>
                                                        <li class="dropdown language-dropdown me-n1">
                                                            <a @click="this.$modal('langModal')"
                                                                ><em class="icon ni ni-globe"></em> <span>{{ $t($i18n.locale == "ua" ? "Ukraine" : $i18n.locale == "ru" ? "Russian" : "English") }}</span></a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="dropdown-inner">
                                                    <ul class="link-list">
                                                        <li>
                                                            <a @click="logOut"
                                                                ><em class="icon ni ni-signout"></em><span>{{ $t("Sign out") }}</span></a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown>
                                        <a v-else @click="this.$modal('loginModal')">{{ $t("Auth") }}</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nk-menu-trigger d-lg-none ms-n1">
                            <a class="nk-nav-toggle nk-quick-nav-icon" @click="mobMenu = !mobMenu"><em class="icon ni ni-menu"></em></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <Modal :id="'langModal'" :size="'sm'">
        <ul class="language-list">
            <li>
                <a @click="lang('en')" class="language-item">
                    <span class="language-name">{{ $t("English") }}</span>
                </a>
            </li>
            <li>
                <a @click="lang('ru')" class="language-item">
                    <span class="language-name">{{ $t("Russian") }}</span>
                </a>
            </li>
            <li>
                <a @click="lang('ua')" class="language-item">
                    <span class="language-name">{{ $t("Ukraine") }}</span>
                </a>
            </li>
        </ul>
    </Modal>
    <Login v-if="!app.auth" />
    <panelMenu v-if="user && user.prava >= 5" :meta="this.$route.meta.panel" :show="panelMenu" />
</template>

<script>
import Login from "../../views/Auth.vue";
import panelMenu from "../panel/menu.vue";

export default {
    components: {Login, panelMenu},
    data() {
        return {
            date: new Date(),
            subMenu1: false,
            subMenu2: false,
            mobMenu: false,
            panelMenu: false,
            sidebarMenu: false,
            loginModal: false,
            email: "",
            password: "",
        };
    },
    mounted() {
        window.addEventListener("mousedown", this.handleMouseDown);
    },
    methods: {
        formatPrice(price) {
            if (typeof price === "string") {
                price = parseFloat(price);
            }
            return price.toFixed(4);
        },
        subMenuShow(id) {
            if (this.subMenu == id) {
                this.subMenu = "";
            } else if (this.subMenu != "" || this.subMenu != id) {
                this.subMenu = id;
            }
        },
        handleMouseDown(event) {
            if (this.subMenu != "") {
                this.subMenu = "";
            }
        },
        lang(lang) {
            this.$cookies.set("lang", lang, {
                path: "/",
                secure: true,
                sameSite: "strict",
            });

            if (Object.keys(this.$i18n.messages[lang]).length > 0) {
                this.$i18n.locale = lang;
            } else {
                this.$i18n.locale = "en";
                this.$i18n.silentFallbackWarn = true;
            }
            this.$modal("langModal");
        },
        async logOut() {
            this.$router.push("/");
            this.$cookies.set("token", "");
            this.$store.dispatch("userOut");
        },
    },
};
</script>
