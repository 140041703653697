import {mapGetters} from "vuex";

let checkUserCalled = false;

export const appMixin = {
    computed: {
        ...mapGetters(["app"]),
        ...mapGetters(["user"]),
        ...mapGetters(["wallet"]),
    },
    created() {
        if (!checkUserCalled) {
            this.checkUser();
            checkUserCalled = true;
        }
    },
    methods: {
        async checkUser() {
            const data = await this.$post("/auth");
            if (data) {
                if (data.error) {
                    this.$toast.show(data.error, {type: "error"});
                } else {
                    this.$store.dispatch("run", data);
                    if(data.wallet) this.$store.dispatch("walletData", data.wallet);
                }
            }
        },
    },
};
